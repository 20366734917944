export default {

  GL2LTR : 3.785411784,
  LTR2GL : 0.264172052358,

  unitVolOpts : [
    { value: null, text: '부피 단위'},
    { value: 'l', text: '리터(L)'},
    { value: 'g', text: '갤런(Gl)'},
    // { value: 'd', text: '드럼(D)'},
    // { value: 'b', text: '배럴(bbl)'},
  ],
  unitLenOpts: [
    { value: null, text: '길이 단위'},
    { value: 'mm', text: '밀리미터(mm)'},
    { value: 'i', text: '인치(In)'},
  ],
  unitTmpOpts: [
    { value: null, text: '온도 단위'},
    { value: 'c', text: '섭씨(°C)'},
    { value: 'f', text: '화씨(°F)'},
  ],

  alarmNameMap : {
    '1': '넘침',
    '2': '부족',
    '3': '화재',
    '4': '수분',
    '5': '누유',
    '6': '넘침2',
    '7': '부족2',
    '8': '예약',
    '9': '센서',
  }
}

// 패킷상태 맵
export const PacketStsMap = {
  PKT_LST : '신호유실',
  PKT_DN  : '신호지연',
  NO_PKT  : '신호없음',
  READY   : '신호대기',
  '0000'  : '정상',
  '': 'unknown'
};
/*
export const PathMap = {
  "/dashboard": "대시보드",
    "/monitor/tank": "모니터/탱크",
    "/monitor/packet": "모니터/실시간패킷",
    "/inventory/tank": "재고조회/탱크별재고",
    "/inventory/oil": "재고조회/유종별 재고",
    "/inventory/inout": "재고조회/입출고 현황",
    "/setting/site": "설정/사이트",
    "/setting/tank": "설정/탱크",
    "/setting/machine": "설정/장비",
    "/setting/oil": "설정/유종",
    "/setting/tank-table": "설정/탱크테이블",
    "/setting/alarms": "설정/경보알람",
    "/setting/services": "설정/시스템서비스",
    "/users/user-info": "사용자/내정보",
    "/users/user-mng": "사용자/사용자관리",
    "/users/access-map": "사용자/접근설정",
    "/users/access-log": "사용자/접근기록"
};*/

export const LogoutCodeMap = {
  '7406': '중복 로그인',
  'U100': '사용자',
  'P100': '비밀번호 만료',
  '7400': '인증만료',
  '8423': '권한없음',
  '8424': '접근거부',
  '3209': '비밀번호 만료',
  '3201': '비밀번호 초기화',
  '': ''
}

export const IoStsMap = {
  'N': '저장',
  'I': '입고',
  'O': '출고',
  'R': '급유',
  'D': '배유',
  'A': '인증',
  'AR': '급유인증',
  'AD': '배유인증',
  'F': 'Off',
  '': '미확인',
}


export const IoOpts = [
  {value:'N',  text:'저장'},
  {value:'I',  text:'입고'},
  {value:'O',  text:'출고'},
  {value:'R',  text:'급유'},
  {value:'D',  text:'배유'},
  {value:'A',  text:'인증'},
  {value:'F',  text:'Off'},
  {value:'',  text:'미확인'},
];
export const IoStsOpts = [
  {value:'',  text:'전체'},
  {value:'I',  text:'입고'},
  {value:'O',  text:'출고'},
  {value:'R',  text:'급유'},
  {value:'D',  text:'배유'},
];

export const FuelStsOpts = [
  {value:'',  text:'전체'},
  {value:'R',  text:'급유'},
  {value:'D',  text:'배유'},
];


export const IoStsVariant = {
  'N': 'info',
  'F': 'dark',
  'I': 'danger',
  'O': 'warning',
  'R': 'danger',
  'D': 'warning',
  'A': 'primary',
  'AR': 'primary',
  'AD': 'primary',
  '': 'dark',
}
export const EqCodeMap = {
  '01': 'F-35',
  '02': 'KF-16C',
  '03': 'KF-16D',
  '04': 'FA-50',
  '05': 'KF-5E',
  '06': 'KF-5F',
  '07': 'F-15K',
  '08': 'HH-60',
  '09': 'AS-332',
  '10': 'HH-32',
  '11': 'B-412',
  '12': 'HH-47',
  '13': 'CH-47',
  '14': '기타(장비)',
  '15': '기타(외래기)'
}

// 수령인 군 구분코드
export const MdcdMap = {
  '0':'공군(자부대)',
  '1':'공군(타부대)',
  '2':'육군',
  '3':'해군',
  '4':'해병대',
  '5':'국방부',
  '6':'국직',
  '7':'외국군', // ano = 00-000000 으로 설정
  '8':'대외기관' // ano = 00-000000 으로 설정
}

export const ExcelEditorLabel = {
  footerLeft: (top, bottom, total) => `레코드 ${top} ~ ${bottom} 개 / 총 ${total} 개`,
    first: '처음',
    previous: '이전',
    next: '다음',
    last: '끝',
    footerRight: {
    selected: '선택:',
      filtered: '필터:',
      loaded: '로드:'
  },
  processing: '진행중',
    tableSetting: 'Table Setting',
    exportExcel: '엑셀 추출',
    importExcel: '엑셀 업로드',
    back: '뒤로',
    reset: '리셋',
    sortingAndFiltering: '정렬/필터',
    sortAscending: '내림차순',
    sortDescending: '오름차순',
    near: '≒ 유사',
    exactMatch: '= 일치',
    notMatch: '≠ 불일치',
    greaterThan: '&gt; 초과',
    greaterThanOrEqualTo: '≥ 이상',
    lessThan: '&lt; 미만',
    lessThanOrEqualTo: '≤ 이하',
    regularExpression: '~ 정규식',
    customFilter: '사용자필터',
    listFirstNValuesOnly: n => `List first ${n} values only`,
    apply: '적용',
    noRecordIsRead: '레코드없음',
    readonlyColumnDetected: '읽기전용 컬럼',
    columnHasValidationError: (name, err) => `컬럼 ${name}값 검증 오류: ${err}`,
    noMatchedColumnName: '일치하는컬럼 없음',
    invalidInputValue: '입력값 오류',
    missingKeyColumn: '키컬럼 없음',
    noRecordIndicator: '레코드 없음'
};


export const FuncCodeMap = {
  '00': '저장소-ATG',
  '01': '난방유-ATG',
  '02': '급유',
  '03': '배유',
  '04': '인증',
  '05': 'Off',
  '06': '대기',
  '07': '시험',
}

export const EventColorMap= {
  '1':'warning',
  '2':'warning',
  '3':'danger',
  '4':'info',
  '5':'danger',
  '6':'danger',
  '7':'danger',
  '8':'info',
  '9':'warning'
}

export const TankUseNameMap = {
  '1':'storage',
  '2':'drain',
  '3':'refueler',
  '4':'pipeline',
  '5':'drum',
  '6':'station',
  '7':'boiler',
  '8':'tanker',
  '9':'ship'
};


export const Colors= {
  pipe: '#F0F0F0',
    water: '#0000F0',
    sensor: {
    off: '#606050',
      on: '#16A2B8',
      warn: '#FFC008',
      info: '#047BFF',
      danger: '#DC3545',
      error: '#FF0000',
      unknown: '#FF00FF'
  },
  tm: {
    off: '#001233',
      on: '#FF00FF',
      danger: '#DC3545',
      error: '#FF0000',
      unknown: '#FF00FF',
  }, // 온도계 온도
  text: {
    oil: {norm: 'success', warn: 'danger', info: 'info'},
    wtr: {norm: 'secondary', warn: 'primary', info: 'info'},
  in: 'warning',
      out: 'dark',
  }
}
