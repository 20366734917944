<template>
  <div>
<!--    <BButton size="sm" variant="outline-secondary" class="m-0" block>-->
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['1']" class="mr-1 font-weight-bolder" :variant="badgeColor['1']">넘침</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['6']" class="mr-1 font-weight-bolder" :variant="badgeColor['6']">넘침2</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['2']" class="mr-1 font-weight-bolder" :variant="badgeColor['2']">부족</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['7']" class="mr-1 font-weight-bolder" :variant="badgeColor['7']">부족2</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['4']" class="mr-1 font-weight-bolder" :variant="badgeColor['4']">수 분</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['5']" class="mr-1 font-weight-bolder" :variant="badgeColor['5']">누 유</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['3']" class="mr-1 font-weight-bolder" :variant="badgeColor['3']">고 온</BBadge>
      <BBadge v-b-tooltip.hover.html.v-danger="eventTip['9']" class="font-weight-bolder" :variant="badgeColor['9']">점 검</BBadge>
<!--    </BButton>-->
  </div>
</template>

<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}
</style>

<script>
import {iosDtToLocal } from "@/common/utils";

const _eventColor = {
  '1':'warning', // 넘침
  '2':'warning', // 부족
  '3':'danger',  // 화재
  '4':'info',    // 수분
  '5':'danger',  // 누유
  '6':'danger',  // 넘침2
  '7':'primary',  // 부족2
  '8':'light',   // 점검
  '9':'warning'  // 점검
};

export default {
  name: 'WidgetEvent',
  props: {
    events: { type: Object, default: ()=> {} },
  },
  data () {
    return {
      eventTip: {
        '1':'', '2':'', '3':'', '4':'', '5':'', '6':'', '7':'', '8':'', '9':''
      },
      badgeColor: {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'},

      eventCodeMap: this.$store.state.codeMaps['EVENT'],
    }
  },
  created(){
    // console.log( '&&&&&&&&&&&& widgetEvents created ----> ', this.events );
    let dts = iosDtToLocal(this.events.dt);
    let eventArr = this.events.data;

    if(!eventArr) return;

    if(eventArr && eventArr.length===0){
      this.eventTip = {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': ''}
      return;
    }


    eventArr.map(e => {
      this.badgeColor[e.code] = _eventColor[e.code];
      let message = e.text.replace(/\|/g, '<br/> ■');
      this.eventTip[e.code] = `<b>[${this.eventCodeMap[e.code]}] ${dts}</b> <br/> ■ ${message}`;
    })
  },

  watch: {}
}
</script>
