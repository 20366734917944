import Vue from 'vue'
import Router from 'vue-router'
import {apiAuthAccess} from "@/common/utils";
import store from '@/store'
import TankLorry from "@/views/components/tank-model/TankLorry.vue";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Login = () => import('@/views/Login');
const Credit = () => import('@/views/Credit');
const Machine = () => import('@/views/setting/Machine');
const Tank = () => import('@/views/setting/Tank');
const Site = () => import('@/views/setting/Site');
const Oil = () => import('@/views/setting/Oil');
const TankTable = () => import('@/views/setting/TankTable');
const Alarms = () => import('@/views/setting/Alarms');
const Services = () => import('@/views/setting/Services');

const TankData = () => import('@/views/inventory/TankData');
const OilData = () => import('@/views/inventory/OilData');
const TankInOut = () => import('@/views/inventory/TankInOut');
const Fueling = () => import('@/views/inventory/Fueling.vue');
const Report = () => import('@/views/inventory/Report');
const Packet = () => import('@/views/monitor/Packet');
const MonitorMain = () => import('@/views/monitor/MonitorMain');
const TankMonitor = () => import('@/views/monitor/TankMonitor');

const MapMonitor = () => import('@/views/monitor/MapMonitor.vue');
const TankLorryMonitor = () => import('@/views/monitor/TankLorryMonitor.vue');
const Events = () => import('@/views/monitor/Events');

const UserMng = () => import('@/views/users/UserMng');
const UserInfo = () => import('@/views/users/UserInfo');
const UserLog = () => import('../views/users/UserLog');
const AccessLog = () => import('../views/users/AccessLog');
const AccessMap = () => import('../views/users/AccessMap');
const PasswordReset = () => import('../views/users/PasswordReset');
const GptTest = () => import('../views/sample/Test.vue');
const PacketTest = () => import('../views/sample/PacketTest.vue');

const NotFound = () => import('../views/404.vue');

const requireAuth = () => async (to, from, next) => {
  // console.log( "requireAuth()-------------> isAuth ----->", store.state.isAuth);
  // console.log( 'requireAuth()-------------> from-path -->', from.path );
  console.log( 'requireAuth()-------------> to-path ---->', to.path );
  try {
    if(!store.state.isAuth){ return next('/login') }
    const rs = await apiAuthAccess({path: to.path, name: to.name});
    console.log('requireAuth---apiAuthAccess return --->', rs);
    const {code} = rs;
    switch(code){
      case 200: return next();
      case 8423: return alert("접근 권한이 없습니다.");
      case 8424: return alert("접근이 거부 되었습니다.");
      case 7406:
        // alert("[동일 아이디 로그인] 로그아웃 되었습니다.");
        await store.dispatch('LOGOUT', code+'');
        break;
        // return next('/login', {code: rs.code});

      default:
        await store.dispatch('LOGOUT', code+'');
        break;
        // alert( `[${rs.code}] 로그인이 필요합니다.`);
        // return next('/login', {code: rs.code} );
    }
    /*
    if (rs.code === 200 && store.state.isAuth) {
      return next();
    }else if ( rs.code > 400 ){
      return alert('접근 할 수 없습니다.');
    }else{
      // alert("[WARNING] 비정상 접근입니다.");
      // store.state.isAuth = false;
      next('/login');
    }*/
  }catch(err){
    console.error(err);
  }
};

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
            path: 'dashboard',
            name: 'Dashboard',
            beforeEnter: requireAuth(),
            component: Dashboard
        },
        {
          path: 'monitor',
          redirect: '/monitor/tank',
          name: '모니터링',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'map',
              name: '지도모니터(3D)',
              props: true,
              beforeEnter: requireAuth(),
              component: MapMonitor
            },
            {
              path: 'tank',
              name: '탱크모니터',
              props: true,
              beforeEnter: requireAuth(),
              component: MonitorMain
            },
            {
              path: 'tank-lorry',
              name: '급유차모니터',
              props: true,
              beforeEnter: requireAuth(),
              component: TankLorryMonitor
            },
            {
              path: 'packet',
              name: '실시간패킷',
              beforeEnter: requireAuth(),
              component: Packet
            },
            {
              path: 'events',
              name: '경보현황',
              props: true,
              beforeEnter: requireAuth(),
              component: Events
            },
          ]
        },
        {
          path: 'inventory',
          redirect: '/inventory/tank',
          name: '재고조회',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'tank',
              name: '탱크별재고',
              beforeEnter: requireAuth(),
              component: TankData
            },
            {
              path: 'oil',
              name: '유종별재고',
              beforeEnter: requireAuth(),
              component: OilData
            },
            {
              path: 'inout',
              name: '입출고현황',
              beforeEnter: requireAuth(),
              component: TankInOut
            },
            {
              path: 'fueling',
              name: '급유.배유현황',
              beforeEnter: requireAuth(),
              component: Fueling
            },
            {
              path: 'report',
              name: '자료조회',
              beforeEnter: requireAuth(),
              component: Report
            },
          ]
        },
        {
          path: 'setting',
          redirect: '/setting/tank',
          name: '설정',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'site',
              name: '사이트',
              beforeEnter: requireAuth(),
              component: Site
            },
            {
              path: 'machine',
              name: '장비',
              beforeEnter: requireAuth(),
              component: Machine
            },
            {
              path: 'tank',
              name: '탱크',
              beforeEnter: requireAuth(),
              component: Tank
            },
            {
              path: 'oil',
              name: '저장유종',
              beforeEnter: requireAuth(),
              component: Oil
            },
            {
              path: 'tank-table',
              name: '탱크테이블',
              beforeEnter: requireAuth(),
              component: TankTable
            },
            {
              path: 'alarms',
              name: '경보알람',
              beforeEnter: requireAuth(),
              component: Alarms
            },
            {
              path: 'services',
              name: '시스템서비스',
              beforeEnter: requireAuth(),
              component: Services
            }
          ]
        },
        {
          path: 'users',
          redirect: '/users/user-mng',
          name: '사용자',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'user-info',
              name: '내정보',
              beforeEnter: requireAuth(),
              component: UserInfo
            },
            {
              path: 'user-mng',
              name: '사용자관리',
              beforeEnter: requireAuth(),
              component: UserMng
            },
            {
              path: 'user-log',
              name: '로그인 기록',
              beforeEnter: requireAuth(),
              component: UserLog
            },
            {
              path: 'access-log',
              name: '접근 기록',
              beforeEnter: requireAuth(),
              component: AccessLog
            },
            {
              path: 'access-map',
              name: '접근 설정',
              beforeEnter: requireAuth(),
              component: AccessMap
            }
          ]
        }
      ]
    },
    { path: '/monitoring', name: 'monitoring', props: true, component: TankMonitor },
    { path: '/login', name: 'Login', props: true, component: Login },
    { path: '/credit', name: 'Credit', props: false, component: Credit },
    { path: '/pwd-reset', name: 'PasswordReset', props: true, component: PasswordReset,},
    { path: '/gpt-test', name: 'GptTest', component: GptTest,},
    { path: '/packet-test', name: 'PacketTest', component: PacketTest,},
    { path: '*', name: 'NotFound', component: NotFound },
  ]
})
